

.h-5-rem{
    height: 5rem;
}

.nav-item.active > a{
    color: var(--wafaa-blue);
}

.nav-item > a{
    color: var(--wafaa-grey);
}

.bg-wafaa-blue{
    background-color: var(--wafaa-blue);
}

.bg-wafaa-blue-opacity-50{
    background-color: var(--wafaa-blue-opacity-50);
}

.text-wafaa-blue{
    color: var(--wafaa-blue) !important;
}

.text-wafaa-dark{
    color: var(--wafaa-dark) !important;
}

.text-wafaa-grey{
    color: var(--wafaa-grey) !important;
}

.btn-check:checked+.btn{
    color: var(--wafaa-grey);
    background-color: var(--wafaa-dark);
}

.width-10{
    width: 10px !important;
}

.min-width-10{
    min-width: 10px;
}

.width-20{
    width: 20px;
}

.min-width-20{
   min-width: 20px;
}

.width-30{
    width: 30px;
}

.min-width-30{
    min-width: 30px;
}

.width-40{
    width: 40px;
}

.min-width-40{
    min-width: 40px;
}

.width-50{
    width: 50px;
}

.min-width-50{
    min-width: 50px;
}

.width-100{
    width: 100px;
}

.min-width-100{
    min-width: 100px;
    max-width: 100px;
}

.width-200{
    width: 200px;
}

.min-width-200{
    min-width: 200px;
    max-width: 200px;
}

.width-250{
    width: 250px;
}

.min-width-250{
   min-width: 250px;
   max-width: 250px;
}

.width-300{
    width: 300px;
}

.min-width-300{
    min-width: 300px;
    max-width: 300px;
}

.object-fit-cover{
    object-fit: cover;
}
.object-fit-contain{
    object-fit: contain;
}
.object-fit-fill{
    object-fit: fill;
}
.object-fit-scale-down{
    object-fit: scale-down;
}

.mt-n2{
    margin-top: -1rem!important;
}

.transition-3-ease{
    transition: 3s ease-in;
}

.carousel .carousel-item {
    height: 85vh;
}


/* In your CSS file or inside a <style> tag in the component */
.carousel-caption p {
    font-weight: 700;
    margin-bottom: 0;
}

/* Responsive font sizes */
@media (max-width: 320px) { /* Mobile small (s) */
    .carousel-caption p {
        font-size: 12px; /* Adjust this size for smaller screens */
    }
}

@media (min-width: 320px) and (max-width: 378px) { /* Mobile medium (m) */
    .carousel-caption p {
        font-size: 13px; /* Medium font size for medium screens */
    }
}

@media (min-width: 398px) { /* Larger devices (l) */
    .carousel-caption p {
        font-size: 18px; /* Larger font size for bigger screens */
    }
}
